.str-video__call-participants-screen-view {
  height: 100%;
  display: flex;
  gap: 0.625rem;

  .str-video__call-participants-screen-view__screen {
    // flex: 4;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // height: 100%;
    // gap: 0.5rem;
    // padding: 0.3125rem;
    padding: 0;
  }

  .str-video__call-participants-screen-view__wrapper {
    // position:relative;
    // flex: 1;
    // min-width: 0;
    // min-height: 0;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-width: 0;
    min-height: 0;
    display: flex;
    align-items: stretch; /* 화면을 꽉 채우도록 변경 */
    justify-content: stretch; /* 화면을 꽉 채우도록 변경 */
    padding: 0; /* 패딩 제거 */
    margin: 0; /* 마진 제거 */
    background-color: black; /* 배경색을 검정으로 설정 */
  }

  .str-video__call-participants-screen-view__overlay {
    position: absolute;
    background-color: var(--str-video__overlay-color);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    gap: 1.5625rem;
    padding: 2rem 0.75rem;
  }

  .str-video__call-participants-screen-view__screen__presenter {
    // padding: 0.625rem 0 0.625rem 0.625rem;
    // background-color: var(--str-video__background-color1);
    // border-radius: var(--str-video__border-radius-xs);
    
    padding: 0; /* 패딩 제거 */
    background-color: transparent; /* 배경색 제거 */
    border-radius: 0; /* 테두리 제거 */
  }

  .str-video__call-participants-screen-view__participants-wrapper {
    flex: 1 1;
    overflow-y: auto;
    scrollbar-width: none;
    width: 100%;
    &::-webkit-scrollbar {
      display: none;
    }

    .str-video__call-participants-screen-view__participants {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
    }
  }

  .str-video__call-participants-screen-view__buttons-wrapper {
    position: relative;
    display: flex;
    flex: 1 1;
    align-items: center;
    flex-direction: column;

    .str-video__call-participants-screen-view__button-up {
      position: absolute;
      top: 0.5rem;
      z-index: 1;

      .str-video__call-controls__button--icon {
        width: 0.8rem;
        height: 0.8rem;
      }
    }
    .str-video__call-participants-screen-view__button-down {
      position: absolute;
      bottom: 0.5rem;
      z-index: 1;

      .str-video__call-controls__button--icon {
        width: 0.8rem;
        height: 0.8rem;
      }
    }
  }

  .str-video__video--screen-share {
    //object-fit: contain
    object-fit: cover
  }
}
