.quiz-tally-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.quiz-tally-modal-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: #0d0d0d;
  padding: 32px 32px 24px 32px;
  border-radius: 24px;
  max-width: 860px;
  min-width: 500px;
  box-shadow: 0px 14px 34px 0px rgba(0, 0, 0, 0.75);

  &__title {
    font-size: 16px;
    margin: 0;
  }

  &__quiz-title {
    margin: 0;
    background-color: #333;
    border: 1px solid #666;
    padding: 8px 16px;
    border-radius: 24px;
    font-size: 14px;

    .underline {
      text-decoration: underline;
      margin-right: 8px;
    }
  }

  &__quiz-list {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    list-style: none;
    margin: 0;
    font-size: 14px;

    border-radius: 24px;
    border: 1px solid var(--gray-800, #333);
    background: var(--gray-900, #191919);
    box-shadow: 0px 0px 24px -4px rgba(0, 0, 0, 0.64);

    // 스크롤바 안보이게 설정
    max-height: 340px;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }

    li {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &__option {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 36px;
    padding: 8px 16px;
    border-radius: 24px;
    width: 100%;
    background-color: #333;
    border: 1px solid #666;
    margin: 0;

    &.correct {
      border-color: #34d399;
    }
  }

  &__option-count {
    min-width: 40px;
    text-align: center;

    &.correct {
      color: #34d399;
    }
  }

  &__button-group {
    display: flex;
    justify-content: end;
    gap: 8px;
    margin-right: 16px;
  }

  &__button {
    width: 96px;
    padding: 14px 16px;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
  }

  .close-button {
    background-color: #2563eb;
    color: #fff;
  }
}

@media only screen and (max-width: 600px) {
  .quiz-tally-modal-content {
    max-width: 400px;
    min-width: 300px;

    &__button {
      width: 88px;
      padding: 10px 12px;
    }
  }
}
