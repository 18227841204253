.quiz-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.quiz-modal-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #0d0d0d;
  padding: 32px 32px 24px 32px;
  border-radius: 24px;
  max-width: 860px;
  min-width: 500px;
  box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.75);
  color: #ffffff;

  &__desc {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    margin: 0;
    padding: 8px 16px;
    gap: 8px;

    .underline {
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }

  &__options {
    display: flex;
    flex-direction: column;
    gap: 8px;
    list-style: none;
    padding: 12px;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px;
      border-radius: 24px;
      border: 1px solid #666;
      cursor: pointer;
      background: #191919;
      transition: background 0.3s ease;

      input.quiz-modal-content__input {
        width: 18px;
        height: 18px;
        cursor: pointer;
        accent-color: #ffffff;
      }

      &:hover {
        background: #333333;
      }

      &.selected {
        background: #2563eb;
      }
    }
  }

  &__button-group {
    display: flex;
    justify-content: end;
    gap: 8px;
  }

  &__button {
    width: 96px;
    padding: 14px 16px;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;

    &.cancel-button {
      background: #f2f2f2;
      color: #999999;
    }

    &.submit-button {
      background: #2563eb;
      color: #ffffff;
    }
  }
}

@media only screen and (max-width: 600px) {
  .quiz-modal-content {
    max-width: 360px;
    min-width: 280px;
  }
}
